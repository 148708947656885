// 
// 
// user-variables.scss
//
// Place your own variable overrides here, these will override any Bootstrap and theme variables.


// Color Scheme
// Uncomment the variables below and change the hex values.

// $primary:      #000;
// $primary-2:     #000;
// $primary-3:     #000;

// Do not change or remove the $theme-color-scheme or $theme-colors maps below

$header-height: 100vh;
$breakpoint-mobile-l: 425px;
$breakpoint-tablet: 768px;
$breakpoint-table: 768px;
$breakpoint-desktop: 992px;
$breakpoint-fullhd: 1440px;

$theme-color-scheme: (
        "primary": $primary,
        "primary-2": $primary-2,
        "primary-3": $primary-3
);

$theme-colors: (
        "primary": $primary,
        "primary-2": $primary-2,
        "primary-3": $primary-3,
        "primaty-gradient": #7eff4e
);

$enable-gradients: false;

$navbar-dark-color: rgba($white, .9);
$navbar-dark-hover-color: rgba($white, 1);
$text-color: #35375A;

$font-family-nunito-sans: 'Nunito Sans',-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-weight-bold: 700;

$font-family-nunito-sans: 'Nunito Sans',-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-weight-bold: 700;

