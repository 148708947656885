.second-header {
  padding: 167px 0 190px;
  min-height: calc(100vh + 80px);
  @media (min-width: $breakpoint-desktop) {
    padding: 167px 0 190px;
  }
  &__container {
    display: grid;
    grid-template-columns: 1fr;
    @media (min-width: $breakpoint-desktop) {
      grid-template-columns: 482px 1fr;
    }

    form {
      display: grid;
      grid-template-columns: 1fr;
      max-width: 300px;
      width: 100%;
      @media (min-width: $breakpoint-desktop) {
        grid-template-columns: 1.5fr 1fr;
        max-width: 100%;
        grid-column-gap: 10px;
      }

      input {
        height: 60px;
      }

      button {
        height: 60px;
      }
    }
  }

  &__left {
    display: grid;
    grid-row: 2/2;
    grid-gap: 50px;
    grid-template-columns: 1fr;
    text-align: center;
    justify-items: center;
    font-family: $font-family-nunito-sans;
    font-size: 1.24em;
    @media (min-width: $breakpoint-desktop) {
      padding: 50px 0 0;
      text-align: left;
      justify-items: left;
      font-size: 1.24em;
      grid-row: unset;
    }

    p {
      font-family: $font-family-nunito-sans;
      line-height: 30px;
      font-size: 20px;
      margin-bottom: 51px;
    }

    h1 {
      margin-bottom: 0;
      font-family: $font-family-nunito-sans;
      font-size: 36px;
      @media (min-width: $breakpoint-desktop) {
        font-size: 46px;
      }
    }
  }
  &__right {
    grid-row: 1/2;
    display: flex;
    justify-content: center;
    @media (min-width: $breakpoint-desktop) {
      grid-row: unset;
      position: relative;
      right: -25px;
    }
  }
}

.search-background-header {
  background: linear-gradient(180deg, #4ECBDC 0%, #6C62CA 100%);
}

.loyalty-background-header {
  background: linear-gradient(191.13deg, #DC4E76 12.35%, #A946BE 129.45%);
}

.recommendation-background-header {
  background: linear-gradient(180deg, #AF4EDC 0%, #6C62CA 100%);
}
