@font-face {
    font-family: 'Nunito Sans';
    src: local('Nunito Sans Light'), local('NunitoSans-Light'), url('../fonts/nunitosans/Nunitosanslight.woff2') format('woff2'), url('../fonts/nunitosans/Nunitosanslight.woff') format('woff'), url('../fonts/nunitosans/Nunitosanslight.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Nunito Sans';
    src: local('Nunito Sans Regular'), local('NunitoSans-Regular'), url('../fonts/nunitosans/Nunitosansregular.woff2') format('woff2'), url('../fonts/nunitosans/Nunitosansregular.woff') format('woff'), url('../fonts/nunitosans/Nunitosansregular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Nunito Sans';
    src: local('Nunito Sans SemiBold'), local('NunitoSans-SemiBold'), url('../fonts/nunitosans/Nunitosanssemibold.woff2') format('woff2'), url('../fonts/nunitosans/Nunitosanssemibold.woff') format('woff'), url('../fonts/nunitosans/Nunitosanssemibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Nunito Sans';
    src: local('Nunito Sans Italic'), local('NunitoSans-Italic'), url('../fonts/nunitosans/Nunitosansitalic.woff2') format('woff2'), url('../fonts/nunitosans/Nunitosansitalic.woff') format('woff'), url('../fonts/nunitosans/Nunitosansitalic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: 'Nunito Sans';
    src: local('Nunito Sans ExtraLight Italic'), local('NunitoSans-ExtraLightItalic'), url('../fonts/nunitosans/Nunitosansextralightitalic.woff2') format('woff2'), url('../fonts/nunitosans/Nunitosansextralightitalic.woff') format('woff'), url('../fonts/nunitosans/Nunitosansextralightitalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}
@font-face {
    font-family: 'Nunito Sans';
    src: local('Nunito Sans ExtraBold Italic'), local('NunitoSans-ExtraBoldItalic'), url('../fonts/nunitosans/Nunitosansextrabolditalic.woff2') format('woff2'), url('../fonts/nunitosans/Nunitosansextrabolditalic.woff') format('woff'), url('../fonts/nunitosans/Nunitosansextrabolditalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}
@font-face {
    font-family: 'Nunito Sans';
    src: local('Nunito Sans ExtraBold'), local('NunitoSans-ExtraBold'), url('../fonts/nunitosans/Nunitosansextrabold.woff2') format('woff2'), url('../fonts/nunitosans/Nunitosansextrabold.woff') format('woff'), url('../fonts/nunitosans/Nunitosansextrabold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}
@font-face {
    font-family: 'Nunito Sans';
    src: local('Nunito Sans Light Italic'), local('NunitoSans-LightItalic'), url('../fonts/nunitosans/Nunitosanslightitalic.woff2') format('woff2'), url('../fonts/nunitosans/Nunitosanslightitalic.woff') format('woff'), url('../fonts/nunitosans/Nunitosanslightitalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: 'Nunito Sans';
    src: local('Nunito Sans Black'), local('NunitoSans-Black'), url('../fonts/nunitosans/Nunitosansblack.woff2') format('woff2'), url('../fonts/nunitosans/Nunitosansblack.woff') format('woff'), url('../fonts/nunitosans/Nunitosansblack.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}
@font-face {
    font-family: 'Nunito Sans';
    src: local('Nunito Sans ExtraLight'), local('NunitoSans-ExtraLight'), url('../fonts/nunitosans/Nunitosansextralight.woff2') format('woff2'), url('../fonts/nunitosans/Nunitosansextralight.woff') format('woff'), url('../fonts/nunitosans/Nunitosansextralight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}
@font-face {
    font-family: 'Nunito Sans';
    src: local('Nunito Sans Bold Italic'), local('NunitoSans-BoldItalic'), url('../fonts/nunitosans/Nunitosansbolditalic.woff2') format('woff2'), url('../fonts/nunitosans/Nunitosansbolditalic.woff') format('woff'), url('../fonts/nunitosans/Nunitosansbolditalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}
@font-face {
    font-family: 'Nunito Sans';
    src: local('Nunito Sans SemiBold Italic'), local('NunitoSans-SemiBoldItalic'), url('../fonts/nunitosans/Nunitosanssemibolditalic.woff2') format('woff2'), url('../fonts/nunitosans/Nunitosanssemibolditalic.woff') format('woff'), url('../fonts/nunitosans/Nunitosanssemibolditalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}
@font-face {
    font-family: 'Nunito Sans';
    src: local('Nunito Sans Bold'), local('NunitoSans-Bold'), url('../fonts/nunitosans/Nunitosansbold.woff2') format('woff2'), url('../fonts/nunitosans/Nunitosansbold.woff') format('woff'), url('../fonts/nunitosans/Nunitosansbold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Nunito Sans';
    src: local('Nunito Sans Black Italic'), local('NunitoSans-BlackItalic'), url('../fonts/nunitosans/Nunitosansblackitalic.woff2') format('woff2'), url('../fonts/nunitosans/Nunitosansblackitalic.woff') format('woff'), url('../fonts/nunitosans/Nunitosansblackitalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}
