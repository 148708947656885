.success-story {
  padding: 100px 5px 70px;

  &__chart {
    position: relative;
    top: -40px;
  }

  &__header {
    margin-bottom: 30px;

    @media (min-width: $breakpoint-desktop){
      margin-bottom: 100px;
    }
  }

  &__title {
    text-align: center;
    @media (min-width: $breakpoint-desktop) {
      text-align: left;
    }
  }

  &__content {
    font-family: $font-family-nunito-sans;
    font-weight: 600;
    font-size: 2em;
    line-height: 3rem;
    color: $primary-2;
    margin-bottom: 20px;
    text-align: center;
    @media (min-width: $breakpoint-desktop) {
      font-size: 2.312em;
      padding: 70px 60px;
      text-align: left;
    }
  }

  &__item {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 20px;
    @media (min-width: $breakpoint-desktop) {
      margin-bottom: 0;
    }
  }

  &__ark-story {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    span {
      margin-left: 20px;
    }
  }

  h2, h3 {
    font-weight: bold;
    font-size: 1.125em;
    line-height: 137%;
    text-transform: uppercase;
  }

  h2 {
    color: $primary-2;
  }
}
