.footer {
  background: linear-gradient(191.74deg, #35375A 13.15%, #6C62CA 156.44%);
  @media (min-width: $breakpoint-desktop) {
    padding: 196px 0;
  }
  padding: 150px 0;
  &__top {
    padding-bottom: 42px;
    border-bottom: 0.25px solid rgba(255, 255, 255, 0.5);
    @media (min-width: $breakpoint-desktop) {
      padding-bottom: 92px;
    }
  }
  &__bot {
  }
  &__body {
    display: grid;
    @media (min-width: $breakpoint-desktop) {
      grid-template-columns: 1fr 1fr 1fr 2fr;
    }
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 40px;
  }
  &__item {
    &-map {
      grid-column: 1/3;
      @media (min-width: $breakpoint-desktop) {
        grid-column: 4;
      }
    }
    &--border {
      border-right: 1px solid #FFFFFF;
    }
  }
  a:not(.btn).footer__link {
    color: inherit;
    font-weight: inherit;
    &:hover {
      color: inherit;
      font-weight: inherit;
    }
  }
  &__nav {
    ul {
      padding: 0;
      margin-bottom: 0;
      list-style-type: none;
      li {
        cursor: pointer;
        &:hover {
          font-weight: $font-weight-bold;
          color: #fff;
        }
      }
    }
    &--header {
      font-weight: $font-weight-bold;
      font-size: 1.007em;
      line-height: 1.3125em;
      text-transform: uppercase;
      margin-bottom: 16px;
    }
    &--item {
      margin-top: 10px;
      font-size: 1em;
      color: rgba(255, 255, 255, 0.69);;
    }
    &--email {
      font-weight: $font-weight-bold;
      div {
        margin-left: 7px;
      }
      svg {
        fill: #fff;
      }
      a {
        display: flex;
        align-items: center;
      }
    }
    &--subitem {
      font-size: 0.6875em;
      text-transform: uppercase;
      color: rgba(255, 255, 255, 0.69);
    }
    &--continent {
      color: #fff;
    }
  }
  &__map {
    margin-bottom: 41px;
  }
  &__feedback {
    font-size: 0.8rem;
  }
  &__feedback-text {
    @media (min-width: $breakpoint-desktop) {
      grid-column: 1/3;
    }
  }
  &__feedback-form {
    display: flex;
    justify-content: center;
    form {
      display: grid;
      grid-template-columns: 280px;
      justify-items: center;
      grid-row-gap: 15px;
      @media  (min-width: $breakpoint-desktop){
        grid-template-columns: 3fr 1.5fr;
      }
    }
  }
  &__feedback-group {
    max-width: 300px;
  }
  &__bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 22px;
  }
}
