.rec-ben {
  display: flex;
  justify-content: center;
  font-family: $font-family-nunito-sans;
  padding: 71px 0;
  &__header {
    text-align: center;
    margin-bottom: 50px;
    @media (min-width: $breakpoint-desktop) {
      margin-bottom: 110px;
    }
  }
  &__body {
    display: grid;
    @media (min-width: $breakpoint-desktop) {
      grid-template-columns: 1fr 1fr;
    }
  }
  &__left {
    @media (min-width: $breakpoint-desktop) {
      padding-right: 150px;
    }
  }
  &__right {
    display: none;
    @media (min-width: $breakpoint-desktop) {
      display: block;
    }
  }
  &__info-item {
    margin-bottom: 40px;
  }
  &__info-title {
    font-weight: $font-weight-bold;
    font-size: 23px;
    line-height: 28px;
    margin-bottom: 20px;
    color: #212529;
    padding-left: 45px;
    @media (min-width: $breakpoint-desktop) {
      padding-left: 0;
    }
  }
  &__info-li {
    font-size: 18px;
    line-height: 32px;
    margin-bottom: 28px;
    color: #495057;
    position: relative;
    padding-left: 45px;
    @media (min-width: $breakpoint-desktop) {
      padding-left: 0;
    }
  }
  &__info-li:before {
    content: '';
    width: 36px;
    height: 36px;
    position: absolute;
    border-radius: 18px;
    background: rgba(108, 98, 202, 0.1);
    left: -0px;
    @media (min-width: $breakpoint-desktop) {
      left: -68px;
    }
    top: 4px;
  }
  &__info-li:after {
    content: '';
    width: 4.20px;
    height: 4.20px;
    background: #9875D9;
    position: absolute;
    left: 15.9px;
    @media (min-width: $breakpoint-desktop) {
      left: -52.1px;
    }
    top: 19.9px;
  }
}
