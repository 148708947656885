.pre-info {
  background: linear-gradient(180deg, rgba(189, 189, 189, 0.2) 0%, rgba(255, 255, 255, 0.2) 47.4%, rgba(189, 189, 189, 0.2) 100%);
  height: 50px;
  width: 100%;

  &__container {
    height: 100%;
    display: flex;
    color: $primary;
    align-items: center;
    justify-content: space-between;
  }

  &__left-side {
    display: flex;
    align-items: center;
    font-size: 0.88rem;
  }

  &__item:first-child {
    margin: 0 15px 0 0;
  }

  &__item:last-child {
    margin: 0 0 0 15px;
  }

  &__item {
    margin: 15px;
    display: flex;
    align-items: center;
    min-width: 120px;
    img {
      height: 14px;
    }
  }
}
