.features3 {
  font-family: $font-family-nunito-sans;
  &__header {
    display: flex;
    justify-content: center;
    margin-bottom: 60px;
    @media (min-width: $breakpoint-desktop) {
      margin-bottom: 140px;
    }
  }
  &__container {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 50px;
    @media (min-width: $breakpoint-desktop) {
      grid-template-columns: repeat(12, 1fr);
      grid-row-gap: 100px;
    }
  }
  &__icon {
    margin-bottom: 20px;
  }
  &__title {
    font-size: 23px;
    font-weight: $font-weight-bold;
    line-height: 26px;
    color: $primary-3;
    margin-bottom: 14px;
  }
  &__contant {
    color: $primary-4;
    line-height: 26px;
  }
  &__item {
    &--hide-mobile {
      display: none;
    }
    @media (min-width: $breakpoint-desktop) {
      grid-column: 1 / 7;

      &--hide-mobile {
        display: block;
      }
      & > .features3__item-body {
        padding: 0 70px 0 0;
      }

      &:nth-child(2n) {
        grid-column: 7 / 13;
        & > .features3__item-body {
          padding: 0 0 0 70px;
        }
      }
      &:nth-child(5n+5) {
        grid-column: 1/4;
        & > .features3__item-body {
          padding: 0;
        }
      }
      &:nth-child(6n+6) {
        grid-column: 5/9;
        img {
          margin-top: -30px;
        }
      }
      &:nth-child(7n+7) {
        grid-column: 9 / 13;
        & > .features3__item-body {
          padding: 0 0 0 70px;
        }
      }
    }
  }
}
