$iconSize: 30px;
$iconSizeLG: 72px;

.benefits {
  padding-bottom: 50px;
  background: rgba(99, 132, 208, 0.07);
  position: relative;

  &--none-background {
    background: none;
    padding: 0;
    margin-top: 100px;
    @media (min-width: $breakpoint-desktop) {
      margin-top: 190px;
    }
  }
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: $font-family-nunito-sans;
  }
  &__background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: -120px
  }
  &__background2 {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  &__products-image {
    position: absolute;
    display: flex;
    justify-content: center;
    max-height: 358px;
    width: 100%;
    top: -180px;
    @media (min-width: $breakpoint-mobile-l) {
      top: -240px;
    }
    @media (min-width: $breakpoint-tablet) {
      top: -280px;
    }
    @media (min-width: $breakpoint-desktop) {
      top: -280px;
    }
  }
  @media (min-width: $breakpoint-desktop) {
    padding-bottom: 100px;
  }
  &__header {
    padding: 50px 0;
    position: relative;
    z-index: 1;
    @media (min-width: $breakpoint-desktop) {
      padding: 96px 0;
    }
  }
  &__body {
    width: 100%;
    padding: 0 10px;
    position: relative;
    z-index: 1;
    @media (min-width: $breakpoint-desktop) {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-column-gap: 25px;
      grid-row-gap: 24px;
      div.card.card-sm + .card-sm, div.card {
        margin: 0;
      }
    }
  }
  &__item {
    display: grid;
    grid-template-columns: $iconSize auto $iconSize;
    grid-gap: 10px;
    align-items: center;
    @media (min-width: $breakpoint-desktop) {
      grid-gap: 29px;
      grid-template-columns: 1fr;
      justify-items: flex-start;
    }
    &-header {
      img {
        height: $iconSize;
        width: $iconSize;
        align-self: center;
        justify-self: center;
        &.icon {
          width: $iconSize * 0.8;
          height: $iconSize * 0.8;
        }
        @media (min-width: $breakpoint-desktop) {
          height: $iconSizeLG;
          width: $iconSizeLG;
          justify-self: start;
        }
      }
      h3 {
        font-family: $font-family-nunito-sans;
        font-weight: $font-weight-bold;
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 0;
        text-align: left;
        color: $gray-700;
        @media (min-width: $breakpoint-desktop) {
          font-size: 23px;
          text-align: left;
          line-height: 28px;
        }
      }
    }
    &-content {
      padding-top: 15px;
      font-family: $font-family-nunito-sans;
      font-size: 15px;
      line-height: 20px;
      color: $gray-700;
      @media (min-width: $breakpoint-desktop) {
        padding-top: 20px;
        font-size: 17px;
        line-height: 26px;
      }
    }
  }
}
