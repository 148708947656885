.slider-buttons {
  display: flex;

  &__item {
    margin: 0 7.5px;
    cursor: pointer;
  }

  &__item:first-child {
    margin: 0 7.5px 0 0;
  }

  &__item--white {
    fill: $white;
  }

  &__item--gray {
    fill: rgba($white, .5);
  }
}
