.features {
  color: #fff;
  padding: 100px 12px 77px;
  font-family: $font-family-nunito-sans;
  font-size: 1.1rem;
  letter-spacing: 0.4px;
  @media (min-width: $breakpoint-desktop) {
    font-size: 1.375em;
  }

  &__header {
    margin-bottom: 45px;
    @media (min-width: $breakpoint-desktop) {
      margin-bottom: 100px;
    }
    h1 {
      margin-bottom: 0;
    }
  }

  &__body {
    position: relative;
    z-index: 2;
    margin-bottom: 100px;
    @media (min-width: $breakpoint-desktop) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 66px;
    }
  }
  &__item {
    padding: 0 20px;
    border-bottom: 1px solid #BDBDBD;
    &:last-child {
      border-bottom: unset;
    }
  }
  &__item--lg-without-border {
    @media (min-width: $breakpoint-desktop) {
      border-bottom: unset;
    }
  }

  &__item-header {
    padding: 30px 0 30px;
    font-weight: $font-weight-bold;
  }
  &__item-content {
    padding: 0 0 40px;
  }
}
