.products-wrapper {
  padding: 0 0 42px;
  @media (min-width: $breakpoint-desktop) {
    padding: 84px 0;
  }
}
.cxify-products {
  padding: 20px 0 0;

  a, .a {
    color: #9875D9;
    font-size: 1.089em;
    line-height: 180%;
    font-weight: $font-weight-bold;
  }

  &__header {
    margin-bottom: 62px;
  }

  &__body {
    display: grid;
    grid-template-columns: repeat(auto-fill, 23%);
    grid-gap: 17px;
    justify-content: center;
    &--explorer {
      display: flex;
    }
  }
  &__body--three {
    display: grid;
    grid-template-columns: repeat(auto-fill, 27%);
    grid-gap: 63px
  }

  &__item:first-child {
    margin-left: 0;
  }

  &__item:last-child {
    margin-right: 0;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    box-sizing: border-box;
    border: 0.968566px solid #E0E0E0;
    box-shadow: 0 3.87426px 3.87426px rgba(0, 0, 0, 0.25);
    border-radius: 9.68566px;
    padding: 50px 33px;
    max-width: 100%;
    &--explorer {
      width: 493px;
    }
    :first-child {
    }

    :last-child {
      margin-right: 0;
    }

  }

  &__body-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: $font-family-nunito-sans;
    .image-wrapper {
      display: flex;
      justify-content: center;
    }
  }

  &__item-image {
    height: 50px;
    @media (min-width: $breakpoint-desktop) {
      height: 104px;
      margin-bottom: 42px;
    }
    &--explorer {
      width: 94px;
      height: 94px;
      margin-bottom: 49px;
    }
  }

  &__title {
    font-family: $font-family-nunito-sans;
    font-weight: $font-weight-bold;
    font-style: normal;
    font-size: 16px;
    line-height: 137%;
    color: $primary-3;
    letter-spacing: 0.968566px;
    @media (min-width: $breakpoint-desktop) {
      margin-bottom: 18px;
      font-size: 16px;
    }
    @media (min-width: $breakpoint-fullhd) {
      font-size: 18.5px;
    }
  }

  &__content {
    font-size: 17px;
    color: $primary-3;
    font-family: $font-family-nunito-sans;
    font-style: normal;
    font-weight: $font-weight-normal;
    line-height: 26px;
  }
}
