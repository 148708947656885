.additional-benefits {
  position: relative;
  background: linear-gradient(160.71deg, #35375A 14.94%, #6C62CA 48.29%);
  display: flex;
  justify-content: center;
  top: 1px;
  @media (min-width: $breakpoint-mobile-l) {
    top: 0;
  }
  @media (min-width: $breakpoint-desktop) {
    padding: 160px 0;
  }

  &__title {
    text-align: center;
    margin-bottom: 50px;
    @media (min-width: $breakpoint-desktop) {
      margin-bottom: 100px;
    }
  }

  &__list {
    padding: 10px;
    display: grid;
    @media (min-width: $breakpoint-desktop) {
      grid-template-columns: 1fr 1fr;
      grid-gap: 60px;
      grid-auto-rows: minmax(min-content, max-content);
    }

    &-left, &-right {
      display: flex;
      flex-direction: column;
    }
  }

  &__item {
    display: grid;
    grid-template-columns: 30px auto 30px;
    grid-gap: 0 10px;
    align-items: center;
    margin-bottom: 20px;

    h3 {
      color: $white;
      margin-bottom: 0;
      font-size: 18px;
    }

    @media (min-width: $breakpoint-desktop) {
      margin-bottom: 0;
      grid-gap: 0 30px;
      grid-template-columns: 71px auto;
      align-items: start;
    }
  }

  &__content {
    &--desktop {
      display: none;
    }

    @media (min-width: $breakpoint-desktop) {
      padding-bottom: 28px;
      border-bottom: 0.75px solid #E0E0E0;
      &--mobile {
        display: none;
      }
      &--desktop {
        display: block;
      }
    }
  }

  &__header {
    background: unset;
    border: unset;
    color: $white;
    flex: unset;
    margin-bottom: 10px;

    &.card.card-body {
      padding: 0;
    }

    @media (min-width: $breakpoint-desktop) {
      margin-bottom: 53px;
    }
  }

  &__content-title {
    @media (min-width: $breakpoint-desktop) {
      margin-bottom: 25px;
    }
  }

  &__container {
    position: relative;
    @media (min-width: $breakpoint-desktop) {
      padding-bottom: 50px;
    }
  }

  &__image {
    position: absolute;
    right: 0;
    bottom: 0;
    display: none;
    @media (min-width: $breakpoint-desktop) {
      display: block;
    }
  }
}
