.loyalty-members {
  display: flex;
  justify-content: center;
  font-family: $font-family-nunito-sans;
  color: #212529;

  &__header {
    text-align: center;
    margin-bottom: 35px;
  }

  &__short-desc {
    text-align: center;
    font-size: 18px;
  }

  &__body {
    display: flex;
    font-family: $font-family-nunito-sans;
    flex-direction: column;
    @media (min-width: $breakpoint-desktop) {
      flex-direction: row;
    }
  }

  &__right {
    padding: 0 20px;
    @media (min-width: $breakpoint-desktop) {
      width: 50%;
      padding: 0 8.5% 0;
    }
  }

  &__left {
    padding-bottom: 40px;
    @media (min-width: $breakpoint-desktop) {
      width: 50%;
    }
  }

  &__title {
    font-weight: $font-weight-bold;
    font-size: 26px;
    display: flex;
    justify-content: center;
    text-align: center;
    @media (min-width: $breakpoint-desktop) {
      font-size: 36px;
      text-align: left;
      margin-bottom: 56px;
    }
  }

  &__text {
    max-width: 433px;
  }

  &__image {
    width: 100%;
    display: none;
    justify-content: center;
    @media (min-width: $breakpoint-desktop) {
      display: flex;
    }
  }

  &__item-title {
    font-size: 20px;
    font-weight: $font-weight-bold;
    margin-bottom: 18px;
    @media (min-width: $breakpoint-desktop) {
      font-size: 23px;
    }
  }

  &__item-content {
    font-size: 18px;
    margin-bottom: 43px;
    position: relative;
  }

  &__right--padding {
    @media (min-width: $breakpoint-desktop) {
      padding-top: 100px;
    }
  }

  &__light-circle {
    display: none;
    position: absolute;
    left: -56px;
    @media (min-width: $breakpoint-desktop) {
      display: block;
    }
  }
}
