.integration {

  background: $primary-2;
  color: $white;
  display: flex;
  font-size: 1em;
  line-height: 180%;

  @media (min-width: $breakpoint-desktop) {
    font-size: 1.25em;
  }
  .container {
    padding: 96px 0 100px;
  }
  &__header {
    font-weight: $font-weight-bold;
  }
  &__text {
    padding: 15px 0 45px;
  }
  &__content {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  &__content-body {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    @media (min-width: $breakpoint-desktop) {
      width: 56%;
    }
  }
  &__sap-icon {
    width: 175px;
    height: 80px;
    position: relative;
    top: -15px;
  }
}
