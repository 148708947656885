.header {
  background: linear-gradient(180deg, $primary 0%, #6C62CA 100%);
  font-family: $font-family-nunito-sans;
  min-height: $header-height;
  margin-bottom: 14px;
  .rocket {
    height: 100px;
  }
  section {
    height: 100%;
  }

  &__container {
    position: relative;
    height: 88.888%;
    z-index: 3;
  }

  .flickity-viewport {
    position: unset;
  }

  .carousel-cell {
    height: 100%;
  }

  &__pre-title {
    margin-bottom: 29px;
    @media (min-width: $breakpoint-desktop) {
      margin-bottom: 43px;
    }
  }

  &__image-wrapper {
    position: absolute;
    right: 4.4%;
    bottom: 96px;
    z-index: -1;

    &--percent {
      //bottom: 200px;
    }
  }

  &__divider-2 {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 2;
  }

  &__divider.flip-x {
    position: absolute;
    width: 100%;
    bottom: 0;
    transform: matrix(1, 0, 0, -1, 0, 0);
    z-index: 3;

    .svg-content {
      width: 100%;
      transform: matrix(1, 0, 0, -1, 0, 0);
    }
  }

  &__vector {
    margin-bottom: 20px;
    cursor: pointer;
    @media (min-width: $breakpoint-desktop) {
      margin-bottom: 32px;
    }
  }

  &__slider-buttons {
    position: relative;
    margin-bottom: 20px;
    display: flex;
    width: 100%;
    justify-content: center;
    z-index: 5;
    @media (min-width: $breakpoint-desktop) {
      width: auto;
      justify-content: start;
      margin-bottom: 34px;
    }
  }

  &__learn_more {
    color: #4A2ABA;
    display: flex;
    font-weight: $font-weight-bold;
    justify-content: center;
    align-items: center;
    @media (min-width: $breakpoint-desktop) {
      width: 248px;
    }
  }

  &__title {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px;
    h1 {
      margin-bottom: 45px;
      font-size: calc(3.125em / 1.5);
      letter-spacing: 0.032em;
      line-height: 134.4%;
      @media (min-width: $breakpoint-desktop) {
        font-size: 3.125em;
        margin-bottom: 50px;
      }
    }

    &--little {
      h1 {
        margin-bottom: 45px;
        font-size: calc(2.25em / 1.5);
        font-weight: $font-weight-bold;
        letter-spacing: 0.03em;
        line-height: 150%;
        @media (min-width: $breakpoint-desktop) {
          margin-bottom: 97px;
          font-size: 2.25em;
        }
      }
    }

    @media (min-width: $breakpoint-desktop) {
      align-items: start;
      padding: 0;
    }
  }

  &__navigation-arrows {
    display: flex;
    position: absolute;
    justify-content: space-between;
    align-items: center;
    top: 45%;
    width: 100%;
    padding: 0 10px;
    z-index: 4;
  }

  &__arrow {
    cursor: pointer;
  }
}

.title-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: $font-weight-bold;
  line-height: 137%;
  font-size: 1.28em;
  letter-spacing: 1.13808px;

  svg {
    margin-bottom: 15px;
    @media (min-width: $breakpoint-desktop) {
      margin-bottom: 0;
    }
  }

  &__content {
    margin-left: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__header {
    color: #C2A2F2;
  }

  &__body {
    text-transform: uppercase;
  }

  @media (min-width: $breakpoint-desktop) {
    flex-direction: row;
    justify-content: start;
    align-items: start;
  }
}
