.call-to-action {
  width: 100%;
  padding: 175px 0;
  color: $white;

  &__learn-more {
    width: 300px;
    height: 65px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    @media (min-width: $breakpoint-desktop) {
      min-width: 280px;
    }
  }

  &__section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    font-size: 1.25em;
    letter-spacing: 0.5px;
    line-height: 160%;
    font-family: $font-family-nunito-sans;
    @media (min-width: $breakpoint-desktop) {
      align-items: start;
    }
  }

  &__header {
    margin-bottom: 31px;
  }

  &__content {
    margin-bottom: 66px;
    padding: 0 5px;
    @media (min-width: $breakpoint-desktop) {
      padding: 0;
    }
  }
}
