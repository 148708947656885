.getting-started {
  padding: 50px 0 62px;
  @media (min-width: $breakpoint-desktop) {
    padding: 100px 0 62px;
  }
  .block-header {
    @media (min-width: $breakpoint-desktop) {
      padding-bottom: 40px;
    }
  }
  &__body {
    padding: 45px 0;
  }
  &__image {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__item-header {
    font-family: $font-family-nunito-sans;
    font-style: normal;
    font-weight: $font-weight-bold;
    font-size: 22px;
    line-height: 28px;
  }
  &__item-text {
    color: #495057;
  }
  &__process {
    @media (min-width: $breakpoint-desktop) {
      padding-left: 90px;
    }
  }
  &__button {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
