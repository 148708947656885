.info-numbers {
  background: $primary-2;
  padding: 120px 0;
  font-family: $font-family-sans-serif;
  color: white;
  text-align: center;
  @media (min-width: $breakpoint-desktop) {
    padding: 200px 0;
  }
  &__container {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 70px;
    @media (min-width: $breakpoint-desktop) {
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 140px
    }
  }
  &__header {
    font-weight: $font-weight-bold-title;
    font-size: 52px;
  }
  &__body {
    font-weight: $font-weight-bold-title;
    font-size: 16px;
  }
}
