.features2 {
  padding: 100px 0 80px;
  color: $white;
  &__title {
    width: 100%;
    text-align: center;
    h3 {
      margin-bottom: 0;
    }
  }
  &__body {
    padding: 50px 0;
    @media (min-width: $breakpoint-desktop) {
      padding: 100px 0;
    }
  }
  &__items {
    width: 100%;
    display: grid;
    grid-template-columns: auto 40px;
    @media (min-width: $breakpoint-desktop) {
      grid-template-columns: 1.8fr 2fr 1.3fr;
      border-left: 1.11586px solid $white;
      border-right: 1.11586px solid $white;
      &:first-child {
        border-top: 1.11586px solid $white;
      }
    }
    &--border {
      border-bottom: 1.11586px solid $white;
      @media (min-width: $breakpoint-desktop) {
        border-bottom: unset;
      }
    }
    &--hide-mobile {
      display: none;
      @media (min-width: $breakpoint-desktop) {
        display: grid;
      }
    }
    & > div {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      @media (min-width: $breakpoint-desktop) {
        border-left: 1.11586px solid $white;
        border-bottom: 1.11586px solid $white;
      }
      &:first-child {
        border-left: unset;
        border-top: unset;
      }
    }
  }
  &__item-title {
    padding: 17px 22px 17px 0;
    font-family: $font-family-nunito-sans;
    font-weight: $font-weight-bold;
    font-size: 18px;
    line-height: 26px;
    @media (min-width: $breakpoint-desktop) {
      padding-left: 22px;
    }
  }
  &__item-content {
    padding: 17px 22px 50px 0;
    font-family: $font-family-nunito-sans;
    font-size: 17px;
    line-height: 26px;
    @media (min-width: $breakpoint-desktop) {
      padding: 17px 22px 17px 22px;
    }
  }
  &__item-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-row: 1 / 3;
    grid-column: 2 / 3;
    @media (min-width: $breakpoint-desktop) {
      grid-row: unset;
      grid-column: unset;
    }
  }
}
