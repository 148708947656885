.improvements {
  padding-top: 50px;
  &__list {
    padding-inline-start: 0;
    li {
      display: flex;
      list-style-type: none;
      padding-top: 26px;
      &:last-child {
        div:last-child {
          border-bottom: none;
        }
      }
    }
    li:first-child {
      padding-top: 0;

    }
  }
  @media (min-width: $breakpoint-desktop) {
    padding-top: 100px;
    padding-inline-start: 20px;
  }
  &__thunder-circle {
    width: 1.937rem;
    height: 1.937rem,;
    position: relative;
  }
  &__item-content {
    width: 100%;
    padding: 0 10px 26px 25px;
    font-family: $font-family-nunito-sans;
    font-weight: $font-weight-bold;
    font-size: 1em;
    letter-spacing: 0.4px;
    border-bottom: 1px solid #BDBDBD;
    @media (min-width: $breakpoint-desktop) {
      font-size: 1.375em;
    }
  }
}
