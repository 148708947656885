// 
// 
// user.scss
//
// Place your own custom CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.

html {
  overflow-x: hidden;
}

body {
  overflow-x: hidden;
}

.basic-button {
  width: 251px;
  height: 57px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.little-button {
  width: 144px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.005em;
}


.nav-item {
  text-transform: uppercase;
  padding-right: 2.25rem
}

.block-header {
  font-family: $font-family-nunito-sans;
  font-weight: $font-weight-bold;
  text-align: center;
  display: inline-block;
  width: 100%;
  letter-spacing: 0.5px;
  color: $primary-3;
}

.fill-primary {
  fill: $primary;
}

.decoration-underline {
  text-decoration: underline;
}


.text-block {
  font-family: $font-family-nunito-sans;
  text-align: center;
  font-size: 1.25em;
  letter-spacing: 0.5px;
  line-height: 160%;

  h2 {
    line-height: 155%;
    font-size: 2.25rem;
    font-weight: $font-weight-bold;
    color: $text-color;
  }
}

.bg-primary-light {
  background-color: $primary-light !important;
}

.is-background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  fill: #9875D9;
  object-fit: fill;

  &--fit-cover {
    object-fit: cover;
  }

  &--position-top {
    object-position: top;
  }


  &--position-bottom {
    object-position: bottom;
  }


  &--position-center {
    object-position: center;
  }
}

.features-background {
  z-index: 1;
  background: linear-gradient(169.22deg, #35375A 55.41%, #6C62CA 113.57%);
}

.white-header {
  font-size: 2.25em;
  line-height: 49px;
  letter-spacing: 0.5px;
  color: $white;
  font-family: $font-family-nunito-sans;
  @media (min-width: $breakpoint-desktop) {
    text-align: left;
  }
}

@media (min-width: $breakpoint-desktop) {
  .collapse.dont-collapse-lg {
    display: block;
    height: inherit;
    visibility: visible;
  }
}

.process-vertical li {
  align-items: start;
}

@media (min-width: 768px) {
  .process-vertical li:nth-child(even) .process-circle {
    margin-right: 1.5rem;
    margin-left: -1.5rem;
  }
  .process-vertical li {
    width: 100%;
    margin-left: 0;
  }
  .process-vertical li:nth-child(even) {
    flex-direction: unset;
    text-align: left;
    margin-left: 0;
    margin-right: 0;
  }
  .process-vertical li:not(:last-child):after {
    height: 55%;
    top: 4.125rem;
  }
}
.partners {
  height: 150px;
  .flickity-slide {
    display: flex;
    align-items: center;
    height: 127px;
  }
  .flickity-page-dots {
    bottom: 0.5rem;
    @media (min-width: $breakpoint-desktop) {
      display: none;
    }
  }
  .carousel-cell {
    display: flex;
    padding: 0 10px;
    height: 100%;
    width: 50%;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    counter-increment: carousel-cell;
    img {
      display: block;
    }
    @media (min-width: $breakpoint-desktop) {
      width: 25%;
    }
  }
  .flickity-prev-next-button {
    display: none;
    @media (min-width: $breakpoint-desktop) and (max-width: 1998.98px) {
      display: flex;
    }
  }
  .flickity-prev-next-button:after {
    background-image: url("data:image/svg+xml;utf8,<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M14.8517 18.1136C14.9506 18.2121 15 18.3402 15 18.4977C15 18.6356 14.9506 18.7538 14.8517 18.8523C14.7331 18.9508 14.6045 19 14.4661 19C14.3277 19 14.209 18.9508 14.1102 18.8523L8.14831 12.8841C8.04944 12.7659 8 12.6379 8 12.5C8 12.3621 8.04944 12.2341 8.14831 12.1159L14.1102 6.14773C14.209 6.04924 14.3277 6 14.4661 6C14.6045 6 14.7331 6.04924 14.8517 6.14773C14.9506 6.24621 15 6.37424 15 6.53182C15 6.6697 14.9506 6.78788 14.8517 6.88636L9.42373 12.5L14.8517 18.1136Z' fill='rgba(55, 86, 189, 0.35)'/></svg>");
  }
}
.partners-section {
  padding: 0;
}

.fill-white * {
    fill: #fff !important;
}
.bg-navbar {
  background: linear-gradient(180deg, #35375A 0%, #6C62CA 929.03%);
}

.divider-height {
  height: 96px;
}
.basic-header {
  font-size: 30px;
  line-height: 130%;
  font-weight: $font-weight-bold;
  font-family: $font-family-nunito-sans;
  font-style: normal;
  @media (min-width: $breakpoint-desktop) {
    font-size: 36px;
  }
}
.white-title {
  font-family: $font-family-nunito-sans;
  font-weight: $font-weight-bold;
  font-size: 33px;
  color: $white;
}
.underlined_title {
  font-size: 27px;
  color: $primary;
  margin-bottom: 30px;
  padding: 21px;
  @media (min-width: $breakpoint-desktop) {
    margin-bottom: 67px;
    border-bottom: 1px solid #35375A;
    padding: 0 0 13px;
  }
}

.large-body {
  font-family: $font-family-nunito-sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  @media (min-width: $breakpoint-desktop) {
    font-size: 20px;
    line-height: 32px;
  }
}
.description-body {
  font-family: $font-family-nunito-sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  @media (min-width: $breakpoint-desktop) {
    font-size: 18px;
    line-height: 32px;
  }
}
.learn-more-button {
  font-family: $font-family-nunito-sans;
  font-style: normal;
  font-weight: $font-weight-bold;
  font-size: 17px;
  line-height: 25px;
  letter-spacing: 1px;
  &.hover-arrow:after {
    font-family: $font-family-sans-serif;
    letter-spacing: 0;
    font-size: 18px;
  }
}

.h-80px {
  height: 80px !important;
}

.border-text {
  font-family: $font-family-nunito-sans;
  font-style: normal;
  font-weight: $font-weight-bold;
  font-size: 37px;
  line-height: 48px;
}

.explorer-header {
  background-color: $primary;
  min-height: 93px;
}
