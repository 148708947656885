.about-us {
  padding: 96px 0 96px;
  background: rgba(152, 117, 217, 0.05);
  font-family: $font-family-nunito-sans;
  @media (min-width: $breakpoint-desktop) {
    padding: 96px 0 144px;
  }
  &__body {
    padding: 10px 0;
    display: grid;
    grid-gap: 37px;
    @media (min-width: $breakpoint-desktop) {
      grid-template-columns: 1fr;
    }
  }
  &__header {
    padding: 48px;
  }
  &__item {
    padding: 35px 25px;
    background: #fff;
    @media (min-width: $breakpoint-desktop) {
      padding: 56px 53px;
    }
  }
  &__item-header {
    font-size: 2.187em;
    font-weight: $font-weight-bold;
    letter-spacing: 0.01em;
    color: $primary-2;
    margin-bottom: 15px;
  }
  &__item-body {
    margin-bottom: 30px;
    letter-spacing: 0.01em;
    font-size: 1.125em;
    color: #35375A;
  }
  &__item-footer {
    a {
      color: #9875D9;
      letter-spacing: 1px;
    }
  }
  &__icon {
    min-height: 75px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
}

svg.bg-about-us * {
  fill: rgba(152, 117, 217, 0.05) !important;
}
