.divider-wrapper {
  height: 0;
}
.divider {
  position: relative;
  width: 100%;
  height: 48px;
  z-index: 3;

  @media (min-width: $breakpoint-desktop) {
    height: 96px;
  }
  .mini-rocket {
    display: none;
    position: absolute;
    bottom: 0;
    right: 0;
    transform: matrix(1, 0, 0, -1, 0, 0);
  }

  .svg-content {
    width: 100%;
    transform: matrix(1, 0, 0, -1, 0, 0);
    height: 100%;
  }

  &--up:first-child {
    top: -47px;
    bottom: 0;
    @media (min-width: $breakpoint-desktop) {
      top: -96px;
    }
    .mini-rocket {
      bottom: -196px;
      right: -22px;
      @media (min-width: $breakpoint-desktop) {
        display: block;
      }
      @media (min-width: 1440px) {
        right: 85px;
      }
    }
  }

  &--invisible:first-child {
    background-color: rgba(0,0,0,0);
    top: -79px
  }

  &--down:first-child {
    bottom: -1;
  }

  &--transform {
    transform: matrix(1, 0, 0, -1, 0, 0);
  }
  &.divider-fill-gray {
    svg:not([class*='bg']) {
      * {
        fill: rgba(99, 132, 208, 0.04);
      }
    }
  }
}

