.percents {
  font-family: $font-family-nunito-sans;
  color: #FFFFFF;
  font-size: 20px;
  padding: 20px 0;
  @media (min-width: $breakpoint-desktop) {
    padding: 60px 0;
  }
  &__container {
    display: grid;
    grid-gap: 25px;
    @media (min-width: $breakpoint-desktop) {
      grid-template-columns: 1fr 1fr 1fr;
      max-width: 878px;
    }
  }
  &__item {
    padding: 25px;
    border-radius: 10px;
    &--violet {
      background: #9875D9;
    }
    &--blue {
      background: #6C62CA;
    }
    &--dark-blue {
      background: #35375A;
    }
  }
  &__circle {
    position: relative;
    width: 122px;
    height: 122px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: $font-family-sans-serif;
    font-weight: $font-weight-bold;
    font-size: 43px;
    margin-bottom: 25px;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
