.b2b {
  background: rgba(99, 132, 208, 0.04);
  position: relative;
  font-family: $font-family-nunito-sans;
  display: flex;
  justify-content: center;
  &__container {
    max-width: 751px;
    text-align: center;
  }
  &__image {
    position: absolute;
    display: flex;
    justify-content: center;
    top: -66px;
    width: 100%;
  }
  &__title {
    margin-bottom: 20px;
  }
  &__description {
    margin-bottom: 40px;
  }

}
