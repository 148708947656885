.pricing {
  padding: 50px 0;
  background-color: rgba(99, 132, 208, 0.04);
  position: relative;
  z-index: 0;
  font-family: $font-family-nunito-sans;
  @media (min-width: $breakpoint-desktop) {
    padding: 100px 0 300px;
  }

  &__header {
    display: flex;
    justify-content: center;
    padding: 0 24px 47px 24px;
  }

  &__content {
    display: flex;
    justify-content: center;
    &--violet {
      .pricing__icon {
        display: none;
        &--blue {
          display: none;
        }
        &--violet {
          display: block;
        }
      }

      .checked-icon {
        display: none;
        &--blue {
          display: none;
        }
        &--violet {
          display: block;
        }
      }
    }
    &--blue {
      .pricing__icon {
        display: none;
        &--blue {
          display: block;
        }
        &--violet {
          display: none;
        }
      }

      .checked-icon {
        display: none;
        &--blue {
          display: block;
        }
        &--violet {
          display: none;
        }
      }
    }
  }

  &__container {
    display: grid;
    border-top: 1px solid #E5E5E5;
    max-width: 915px;
    @media (min-width: $breakpoint-desktop) {
      grid-template-columns: 1fr 1fr;
      min-width: 915px;
    }
  }

  &__first-item {
    display: flex;
    justify-content: flex-end;
    padding: 42px 24px;
    @media (min-width: $breakpoint-desktop) {
      grid-gap: 60px;
      border-right: 1px solid #E5E5E5;
    }
  }
  &__second-item {
    padding: 0 24px;
    @media (min-width: $breakpoint-desktop) {
      padding: 42px 50px 0;
    }
  }


  &__started_at {
    font-family: $font-family-nunito-sans;
    font-weight: $font-weight-bold;
    font-size: 16px;
    color: #ADB5BD;
    padding-bottom: 10px;
    @media (min-width: $breakpoint-desktop) {
      padding-bottom: 20px;
    }
  }

  &__amount {
    font-size: 40px;
    color: $primary;
    font-weight: $font-weight-bold;
    @media (min-width: $breakpoint-desktop) {
      font-size: 52px;
    }
  }

  &__per_month {
    font-size: 16px;
    color: $primary;
    font-weight: $font-weight-bold;
    padding-bottom: 27px;
    @media (min-width: $breakpoint-desktop) {
      padding-bottom: 47px;
    }
  }

  &__features-header {
    font-size: 16px;
    font-weight: $font-weight-bold;
    color: $primary;
    padding-bottom: 15px;
    @media (min-width: $breakpoint-desktop) {
      font-size: 22px;
    }
  }

  &__features-list {
    display: grid;
    grid-gap: 18px;
  }

  &__features_item {
    display: grid;
    grid-template-columns: 18px auto;
    grid-gap: 18px;
  }
  &__icon {
    img {
      width: 95px;
    }
  }
}
