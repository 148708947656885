.any-questions {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  align-items: center;
  width: 100%;
  padding-bottom: 30px;
  @media (min-width: $breakpoint-desktop) {
    margin-top: -145px;
    padding-bottom: 63px;
  }
  &__content {
    max-width: 1140px;
    background-color: $primary-2;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 23px 20px 48px;
    overflow: hidden;
    @media (min-width: $breakpoint-desktop) {
      padding: 0 23px 0 48px;
      flex-direction: row;
      border-radius: 10px;
      height: 120px;
    }
  }
  &__title {
    padding-bottom: 40px;
    text-align: center;
  }
  &__text, &__text.white-title {
    font-size: 16px;
    text-align: center;
    margin-bottom: 10px;
    @media (min-width: $breakpoint-desktop) {
      font-size: 30px;
    }
  }
  &__rocket {
    display: none;
    position: relative;
    width: 177px;
    top: 37px;
    @media (min-width: $breakpoint-desktop) {
      display: block;
    }
  }
  &__button {
    width: 210px;
    a {
      width: 100%;
      height: 57px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
